//LeadInfoDialog.jsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import './LeadInfoDialog.css';

const LeadInfoDialog = ({ open, onClose, leadData, apiClient, cUser,  updateLeadStatus}) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [currentStatus, setCurrentStatus] = useState('');
  const [communicationChain, setCommunicationChain] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messageContainerRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState('');


  const fetchComments = useCallback(async () => {
    try {
      const response = await apiClient.get(`/api/comments/${leadData.id_lead}/`);
      setComments(response.comments);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  }, [apiClient, leadData.id_lead]);

  const handleAddComment = async () => {
    try {
      await apiClient.post(`/api/comments/${leadData.id_lead}/`, { text: newComment });
      setNewComment('');
      fetchComments();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

    const handleDeleteComment = async (leadId, commentId) => {
    try {
      await apiClient.del(`/api/comments/${leadId}/${commentId}/`);
      fetchComments();
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };


  const fetchStatuses = useCallback(async () => {
    try {
      const response = await apiClient.get('/api/statuses/');
      setStatuses(response.statuses);
      console.log('!!!!!leadData.id_status:', leadData.id_status); // Для отладки
      setCurrentStatus(leadData.id_status);

    } catch (error) {
      console.error('Error fetching statuses:', error);
    }
  }, [apiClient, leadData.id_status]);



  const handleStatusChange = async (event) => {
    const newStatusId = event.target.value;
    try {
        // Отправляем новый статус на сервер
        await apiClient.post(`/api/update_status/${leadData.id_lead}/`, { status: newStatusId });

        // Находим текстовое значение статуса из списка statuses
        const newStatus = statuses.find(status => status.id_status === newStatusId);
        const newStatusValue = newStatus ? newStatus.status : '';

        // Обновляем состояние текущего статуса
        setCurrentStatus(newStatusId);
        leadData.id_status = newStatusId;
        console.log('!!!!!!!!!!!!!!!! Niw status id ',newStatusValue)

        // Обновляем статус в таблице LeadsDashboard
        if (updateLeadStatus) {
            updateLeadStatus(leadData.id_lead, newStatusValue, newStatusId);
        }
    } catch (error) {
        console.error('Error updating status:', error);
    }
};

  const fetchCommunicationChain = useCallback(async () => {
    //console.log('!!!! cUser=', cUser)
     try {
      const response = await apiClient.get(`/api/communication_chain/${leadData.id_client}/`, {
        params: {
          access_level: cUser.access_level, // Передаем access_level
        }
      });
      setCommunicationChain(response.communication_chain);
      setNewMessage('');
    } catch (error) {
      console.error('Error fetching communication chain:', error);
    }
  }, [apiClient, leadData.id_client,  cUser]);

  const handleSendMessage = async () => {
    if (isSending || !newMessage.trim()) return;
      setError('');
      setIsSending(true);
      try {
        console.log('Sending SMS with data:', {
          text_message: newMessage,
          phone: leadData.phone,
          twilio_phone_number: leadData.from_number,
          operator: cUser.username,
        });
        await apiClient.post(`/api/send_sms/${leadData.id_lead}/`, {
          text_message: newMessage,
          phone: leadData.phone,
          twilio_phone_number: leadData.from_number,
          operator: cUser.username,
        });
        setNewMessage('');
        fetchCommunicationChain();
      } catch (error) {
        setError(error.response?.data?.message || 'An error occurred while sending the message');
        console.error('Error sending message:', error);
      } finally {
        setIsSending(false);
      }
  };

  useEffect(() => {
    if (open && leadData) {
      fetchComments();
      fetchStatuses();
      fetchCommunicationChain();
      setCurrentStatus(leadData.id_status);
      console.log('Dialog opened, current status:', leadData.id_status);
    }
  }, [open, leadData, fetchComments, fetchCommunicationChain, fetchStatuses]);


  const formatPhoneNumber = (phoneNumberString) => {
  // Удаляем все нецифровые символы из строки
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

    // Проверяем, что у нас есть 11 цифр, начинающихся с 1
    const match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      // Форматируем номер в нужный вид
      return `+1 (${match[1]}) ${match[2]}${match[3]}`;
    }

    // Если формат не соответствует, возвращаем исходную строку
    return phoneNumberString;
  };

  const unblockLead = useCallback(async () => {
    try {
      const response = await apiClient.post('/api/unblock_lead/', {
      id_lead: leadData.id_lead
    });
      console.log('!!Unblock lead response:', response);
    } catch (error) {
      console.error('Error unblocking lead:', error);
    }
  }, [apiClient, leadData.id_lead]);

   const handleClose = useCallback((event, reason) => {
      console.log('handleClose called, reason:', reason);
      if (reason !== 'backdropClick') {
        setError('');
        unblockLead();
        onClose();
      }
   }, [setError, unblockLead, onClose]);


   useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault(); // Стандартное поведение для некоторых браузеров
      unblockLead();

      // Для некоторых браузеров может потребоваться возвращение строки
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unblockLead]);

// Прокрутка вниз при обновлении цепочки сообщений
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [communicationChain]);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="lead-info-title">Lead Information</DialogTitle>

     <DialogContent>
  <Grid container spacing={2}>
    <Grid item xs={5} className="sidebar" style={{ width: 'calc(33.33% + 20mm)' }}>
      <div className="client-info box">
        <Typography variant="h6">Client Info</Typography>
        <Divider sx={{ my: 2 }} />
        <Typography><strong>{leadData.client_name}</strong></Typography>
        <Typography>{formatPhoneNumber(leadData.phone)}</Typography>
        <Typography>{`${leadData.address}, ${leadData.city}, ${leadData.state} ${leadData.zip}`}</Typography>
        <Typography>Last service: {leadData.service}</Typography>
      </div>


      <div className="operator-comments box">
              <Typography variant="h6">Comments</Typography>
              <List>
                {comments.map((comment) => (
                  <ListItem key={comment.id_comment} className="note-container">
                    <ListItemText
                      primary={comment.text_comment}
                      style={{
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%'
                      }}
                    />
                    <Button
                      className="delete-button"
                      onClick={() => handleDeleteComment(leadData.id_lead, comment.id_comment)}
                    >
                      Delete
                    </Button>
                  </ListItem>
                ))}
              </List>
              <TextField
                fullWidth
                multiline
                rows={2}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value.slice(0, 150))}
                placeholder="Add a comment (max 150 characters)"
                className="note-container-input"
                inputProps={{maxLength: 150}}
                helperText={`${newComment.length}/150`}
                style={{width: '100%', marginBottom: '10px'}}
              />
        <Button className="add-comment-button" onClick={handleAddComment}>Add Comment</Button>
      </div>

      <div className="status box">
        <Typography variant="h6"> Change status:</Typography>
        <FormControl fullWidth sx={{mt: 2}}>
          <Select
            value={currentStatus}
            onChange={handleStatusChange}
            fullWidth
          >
            {statuses.map((status) => (
              <MenuItem key={status.id_status} value={status.id_status}>
                {status.status}
              </MenuItem>
            ))}
          </Select>

        </FormControl>
      </div>


    </Grid>
    <Grid item xs={7} className="content" style={{width: '66.67%', transform: 'scaleX(1.04)', transformOrigin: 'left'}}>
      <div className="dialogue box">
        <Typography variant="h6">Dialogue</Typography>
        <div className="message-container" ref={messageContainerRef}>
          {communicationChain.map((message, index) => {
            // Format the date_message into a readable format
            const formattedDate = new Date(message.date_message).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            });

            return (
                <Typography key={index} component="p">
                  <strong>
                    {message.message_type === 'Received' ? `Client: ${leadData.client_name}:` : `${message.message_type}:`}
                  </strong>
                  {` ${message.text_message}`}
                  {/* Add the formatted date here */}
                  <span style={{fontSize: '0.8em', color: '#888', marginLeft: '10px'}}>
            {formattedDate}
          </span>
                </Typography>
            );
          })}
        </div>
      </div>
      <Divider/>
      <div className="new-message box">
        <Typography variant="h6">
          New message from: <span style={{fontWeight: 'normal'}}>{leadData.from_number}</span>
        </Typography>
        {error && (
            <Typography color="error" variant="body2" style={{marginBottom: '10px'}}>
              Error: {error}
            </Typography>
        )}
        <TextField
            id="message-text"
            fullWidth
            multiline
            rows={2}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value.slice(0, 150))}
            placeholder="Type your message (max 150 characters)"
            inputProps={{maxLength: 150}}
            helperText={`${newMessage.length}/150`}
        />
        <Divider sx={{my: 2}}/>
        <div className="button-container">
          <Button
              id="send-button"
              onClick={handleSendMessage}
              variant="contained"
              color="primary"
              disabled={isSending || !newMessage.trim()}
          >
            {isSending ? 'Sending...' : 'Send'}
          </Button>
          <Button id="refresh-button" onClick={fetchCommunicationChain}>Refresh</Button>
        </div>
      </div>
      <Divider sx={{my: 2}}/>
      <DialogActions className="dialog-actions">
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Grid>
  </Grid>
     </DialogContent>


    </Dialog>
  );
};

export default LeadInfoDialog;